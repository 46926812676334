.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  /* padding-top: 56px; */
  background-color: #f8f9fa !important;
}

.navbarmargin {
  padding-top: 56px;
}

.floating-button-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.floating-button-left {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

/* // relative to the parent */
.floating-button-topright {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* // relative to the parent */
.floating-button-topleft {
  position: absolute;
  top: 20px;
  left: 20px;
}

.custom-rounded-button {
  border-radius: 12px !important;
}



.custom-option-image {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  margin-right: 8px; /* Add spacing between image and label */
}



.uploadbutton_input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}


.uploadbutton_div {
  position: relative;
  overflow: hidden;
}


